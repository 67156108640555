var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "checkout-items" },
    _vm._l(_vm.products, function(product) {
      return _c(
        "b-card",
        {
          key: product.id,
          staticClass: "ecommerce-card",
          attrs: { "no-body": "" }
        },
        [
          _c(
            "div",
            { staticClass: "item-img" },
            [
              _c(
                "b-link",
                [
                  _c("b-img", {
                    attrs: {
                      src: product.image,
                      alt: product.name + "-" + product.id
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c("b-card-body", [
            _c("div", { staticClass: "item-name" }, [
              _c(
                "h6",
                { staticClass: "mb-0" },
                [
                  _c("b-link", { staticClass: "text-body" }, [
                    _vm._v(" " + _vm._s(product.name) + " ")
                  ])
                ],
                1
              ),
              _c(
                "span",
                { staticClass: "item-company" },
                [
                  _vm._v("By "),
                  _c("b-link", { staticClass: "company-name" }, [
                    _vm._v(_vm._s(product.brand))
                  ])
                ],
                1
              ),
              _c("div", { staticClass: "item-rating" }, [
                _c(
                  "ul",
                  { staticClass: "unstyled-list list-inline" },
                  _vm._l(5, function(star) {
                    return _c(
                      "li",
                      {
                        key: star,
                        staticClass: "ratings-list-item",
                        class: { "ml-25": star - 1 }
                      },
                      [
                        _c("feather-icon", {
                          class: [
                            { "fill-current": star <= product.rating },
                            star <= product.rating
                              ? "text-warning"
                              : "text-muted"
                          ],
                          attrs: { icon: "StarIcon", size: "16" }
                        })
                      ],
                      1
                    )
                  }),
                  0
                )
              ])
            ]),
            _c("span", { staticClass: "text-success mb-1" }, [
              _vm._v("In Stock")
            ]),
            _c(
              "div",
              { staticClass: "item-quantity" },
              [
                _c("span", { staticClass: "quantity-title" }, [_vm._v("Qty:")]),
                _c("b-form-spinbutton", {
                  staticClass: "ml-75",
                  attrs: { size: "sm", inline: "" },
                  model: {
                    value: product.qty,
                    callback: function($$v) {
                      _vm.$set(product, "qty", $$v)
                    },
                    expression: "product.qty"
                  }
                })
              ],
              1
            ),
            _c("span", { staticClass: "delivery-date text-muted" }, [
              _vm._v(
                "Delivery by " +
                  _vm._s(
                    _vm.formatDate(product.shippingDate, {
                      month: "short",
                      day: "numeric",
                      weekday: "short"
                    })
                  )
              )
            ]),
            _c("span", { staticClass: "text-success" }, [
              _vm._v(
                _vm._s(product.discountPercentage) +
                  "% off " +
                  _vm._s(product.offers) +
                  " offers Available"
              )
            ])
          ]),
          _c(
            "div",
            { staticClass: "item-options text-center" },
            [
              _c("div", { staticClass: "item-wrapper" }, [
                _c("div", { staticClass: "item-cost" }, [
                  _c("h4", { staticClass: "item-price" }, [
                    _vm._v(" $" + _vm._s(product.price) + " ")
                  ]),
                  product.hasFreeShipping
                    ? _c(
                        "p",
                        { staticClass: "card-text shipping" },
                        [
                          _c(
                            "b-badge",
                            { attrs: { pill: "", variant: "light-success" } },
                            [_vm._v(" Free Shipping ")]
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ])
              ]),
              _c(
                "b-button",
                {
                  staticClass: "mt-1 remove-wishlist",
                  attrs: { variant: "light" },
                  on: {
                    click: function($event) {
                      return _vm.removeProductFromCartClick(product)
                    }
                  }
                },
                [
                  _c("feather-icon", {
                    staticClass: "mr-50",
                    attrs: { icon: "XIcon" }
                  }),
                  _c("span", [_vm._v("Remove")])
                ],
                1
              ),
              _c(
                "b-button",
                {
                  staticClass: "btn-cart move-cart",
                  attrs: { variant: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.toggleProductInWishlist(product)
                    }
                  }
                },
                [
                  _c("feather-icon", {
                    staticClass: "mr-50",
                    class: { "fill-current": product.isInWishlist },
                    attrs: { icon: "HeartIcon" }
                  }),
                  _c("span", { staticClass: "text-nowrap" }, [
                    _vm._v("Wishlist")
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }